import { ChangeEvent, useState } from 'react'

type Handler<T> = T extends infer O
  ? {
      [K in keyof O]: {
        value: O[K]
        onChange: (
          val: ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
          >,
        ) => void
      }
    }
  : never

//Expand is used to make the type look better en vs code tooltips
type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never

export const useInput = <T extends object>(
  initialValue: T,
): [T, Expand<Handler<T>>] => {
  const [value, setValue] = useState(initialValue)

  return [
    value,
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    Object.keys(initialValue).reduce<any>((memo, key) => {
      memo[key as keyof T] = {
        onChange: (
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ): void => {
          if (event.currentTarget.type === 'checkbox') {
            // @ts-ignore
            setValue((v) => ({ ...v, [key]: event.target.checked }))
          } else {
            setValue((v) => ({ ...v, [key]: event.target.value }))
          }
        },
        value: value[key as keyof T],
      }
      return memo
      // biome-ignore lint/suspicious/noExplicitAny: legacy
    }, {} as any),
  ]
}
